// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
//import "./src/normalize.css"
// custom CSS styles
//import "./src/style.css"
//import "bootstrap/dist/css/bootstrap.min.css"


import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';



export const wrapRootElement = ({ element }) => {
  return (    
    <GoogleReCaptchaProvider
    reCaptchaKey="6LdAn9IZAAAAAHVmubVMv7-vEe8lQ7Z-k82Ebkrf"    
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: "head", // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
  > {element}</GoogleReCaptchaProvider>
  );
}


export const onInitialClientRender = () => {
  console.log("ReactDOM.render has executed")
  
}


export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log('new pathname', location.pathname)
  console.log('old pathname', prevLocation ? prevLocation.pathname : null)
  
}